

















































import { concat } from 'lodash'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import FilesList from '@/components/FilesList.vue'
import Player from '@/components/_uikit/Player.vue'
import SelectFilesButton from '@/components/_uikit/buttons/SelectFilesButton.vue'
import SelectFilesModal from '@/components/modals/SelectFilesModal.vue'
import UploadInput from '@/components/_uikit/controls/UploadInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import { MediaResource, NameValueResource } from '@/store/types'

@Component({
  components: {
    FilesList,
    Player,
    SelectFilesButton,
    SelectFilesModal,
    UploadInput,
  },
})
export default class AttachmentsWrapper extends Mixins(NotifyMixin) {
  @Prop({ default: () => ([]) })
  private files!: MediaResource[]

  @Prop({ default: () => ([]) })
  private value!: number[]

  @Prop({ default: false })
  private readonly!: boolean

  @Prop({ default: false })
  private withoutHeader!: boolean

  @Prop({ default: false })
  private withoutSelect!: boolean

  @Prop({ default: false })
  private withoutUpload!: boolean

  @Prop({ default: false })
  private hideOnSelect!: boolean

  @Prop({ default: false })
  private uploadOnly!: boolean

  @Prop({ default: 'image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
  private accept!: string

  @Prop({ default: 'Материалы' })
  private title!: string

  @Prop({ default: '' })
  private hint!: string

  @Prop({ default: 'Прикрепить файл' })
  private placeholderUpload!: string

  @Prop({ default: 'Добавить файл из хранилища' })
  private placeholderSelect!: string

  @Prop({ default: () => ([]) })
  private additionalUploadParams!: NameValueResource[]

  @Prop({ default: false })
  private single!: boolean

  @Prop({ default: false })
  private showEmptyFilesMessage!: boolean

  @Prop({ default: 'Папка пуста' })
  private emptyFilesMessage!: string

  @Prop({ default: false })
  private isDepository!: boolean

  @Prop({ default: false })
  private isTraining!: boolean

  @Prop({ default: false })
  private iconActionBtn!: boolean

  // Можно ли загрузить файл? (Если prop single === true и 1 файл уже есть, то нельзя)
  private get uploadFile() {
    return this.single && (this.innerFiles.length === 1 || this.innerRecords.length === 1)
  }

  private innerFiles: MediaResource[] = []
  private innerRecords: MediaResource[] = []
  private showSelectFilesModal = false

  private mounted () {
    if (this.files.length) {
      this.innerFiles = this.files.filter(file => file.type !== 'audio')
      this.innerRecords = this.files.filter(file => file.type === 'audio')
    }
  }

  private handleSelectFiles (response: MediaResource[]) {
    this.innerFiles.push(...response.filter(file => file.type !== 'audio'))
    this.innerRecords.push(...response.filter(file => file.type === 'audio'))

    const IDs = [...this.innerFiles.map((file: MediaResource) => file.id), ...this.innerRecords.map((file: MediaResource) => file.id)]
    this.$emit('update:value', IDs)
    this.$emit('select-files', IDs)
    this.$emit('add-files', response)
  }

  private handleUploadFile (response: MediaResource) {
    if (response.type !== 'audio') {
      this.innerFiles.push(response)
    } else {
      this.innerRecords.push(response)
    }

    this.$emit('update:value', concat(this.value, response.id))
    this.$emit('select-files', [response.id])
    this.$emit('add-files', response)
  }

  private handleDeleteFile (id: number) {
    this.innerFiles = this.innerFiles.filter((file: MediaResource) => file.id !== id)
    this.innerRecords = this.innerRecords.filter((file: MediaResource) => file.id !== id)
    this.$emit('update:value', this.value.filter((item: number) => item !== id))
    this.$emit('delete-file', id)
  }

  @Watch('innerFiles')
  private watchInnerValue() {
    this.$emit('changeFiles', this.innerFiles)
  }

  @Watch('innerRecords')
  private watchInnerRecordsValue() {
    this.$emit('changeRecords', this.innerRecords)
  }
}
