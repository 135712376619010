

























import camelCaseKeys from 'camelcase-keys'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import { fileManagerURL, fileManagerTrainerURL, getToken } from '@/utils/services/config'
import NotifyMixin from '@/mixins/NotifyMixin'

interface IFile {
  id: number,
  type: string,
  path: string,
  timestamp: number,
  size: number,
  dirname: string,
  url: string,
  basename: string,
  mediaType: string,
  extension: string,
  filename: string,
}

@Component
export default class FilesSelect extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private visible!: boolean

  @Prop({ default: false })
  private isTraining!: boolean

  private get settings () {
    const token = getToken()
    return {
      baseUrl: this.isTraining ? fileManagerTrainerURL : fileManagerURL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      lang: 'ru',
      windowsConfig: 2,
    }
  }

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
  }

  private handleSelect () {
    const files = this.$store.state.fm.left.files.map((item: any) => camelCaseKeys(item))
    const selected = this.$store.state.fm.left.selected.files

    if (selected.length) {
      this.$emit('select', files.filter((file: IFile) => selected.includes(file.path)).map((file: IFile) => ({
        filename: file.filename,
        id: file.id,
        size: file.size,
        type: file.mediaType,
        url: file.url,
      })))
      this.handleClose()
    } else {
      this.notifyError('Вы не выбрали файлы')
    }
  }

  private handleClose () {
    this.$emit('update:visible', false)
  }
}
